.timeline-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.time-capsule {
    height: 100vh;
    width: 100%;
    background-color: #F5E8C7;
    position: relative;
}

.time-capsule-text-container {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    align-self: center;
    width: 100%;
}

.time-capsule-text {
    color: rgba(0,0,0,0.5);
    font-family: "Avenir Next";
}

.time-capsule-text-arrow {
    color: #AC7088;
}

[class*="timeline-row"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    height: 100vh;
    position: relative;
}

.timeline-row-0 {
    background-color: #F5E8C7;
}

.timeline-row-1 {
    background-color: #ECCCB2;
}

.timeline-row-2 {
    background-color: #DEB6AB;
}

.timeline-row-3 {
    background-color: #CB9BAE;
}

[class*="photo-text"] {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    margin-top: 24px;
    margin-bottom: 24px;
    color: rgba(0,0,0,0.5);
    font-family: "Avenir Next";
}

[class*="photo-title"] {
    align-self: flex-start;
    font-weight: 600;
}

.timeline-photo-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.timeline-photo {
    max-width: 100%;
    width: 100%;
    height: auto;
    max-height: 75%;
    border-radius: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.blur {
    width: 100%;
    height: 300px;
    top: -150px;
    left: 50%;
    filter: blur(30px);
    position: absolute;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(217, 217, 217, 0) 100%
    );
}


@media only screen and (min-width: 577px) {
    .timeline-row-0, .timeline-row-1, .timeline-row-2, .timeline-row-3, .time-capsule {
        width: 70%;
    }

    .timeline-photo {
        object-fit: cover;
    }
}

@media only screen and (min-width: 992px) {
    .timeline-row-0, .timeline-row-1, .timeline-row-2, .timeline-row-3, .time-capsule {
        width: 60%;
    }
}

@media only screen and (min-width: 1200px) {
    .timeline-row-0, .timeline-row-1, .timeline-row-2, .timeline-row-3, .time-capsule {
        width: 50%;
    }
}