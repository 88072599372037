.disclosure-container {
    height: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.disc-header {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 10px;
    padding-left: 20px;
}

.disclosure-header {
    color: #00AFF0;
    font-size: 2.5em;
    font-family: "Marguerite";
}

.only {
    color: white;
    font-family: "Gotham";
}

.lock {
    position: absolute;
    width: 12px;
    height: auto;
    top: 42%;
    left: 31px;
}

.disclosure-title {
    color: red;
}

.disclosure-body {
    color: white;
}

.disclosure-button {
    width: fit-content;
    padding: 10px;
    -webkit-appearance: none;
    background-color: lightgrey;
    border: none;   
    color: black;
    -webkit-border-radius: none;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 8px;
}

@font-face {
    font-family: 'Marguerite';
    src: local('Marguerite'), url(../fonts/marguerite.ttf) format('truetype');
}

@font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(../fonts/gotham.otf) format('opentype');
}