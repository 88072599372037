html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
  background-color: black;
}

body > #root > div {
  height: 100vh;
}

.App {
  text-align: center;
  background-color: black;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.fade-enter {
  opacity: 0.2;
}
.fade-enter-active {
 opacity: 1;
 transition: opacity 3000ms;
}
.fade-exit {
 opacity: 1;
}
.fade-exit-active {
 opacity: 0;
 transition: opacity 3000ms;
}